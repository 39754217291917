@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Medium.eot");
  src: local("DIN Pro Medium"), local("DINPro-Medium"), url("../fonts/DINPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Medium.woff") format("woff"), url("../fonts/DINPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-BlackItalic.eot");
  src: local("DIN Pro Black Italic"), local("DINPro-BlackItalic"), url("../fonts/DINPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-BlackItalic.woff") format("woff"), url("../fonts/DINPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedBlackItalic.eot");
  src: local("DIN Pro Condensed Black Italic"), local("DINPro-CondensedBlackItalic"), url("../fonts/DINPro-CondensedBlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedBlackItalic.woff") format("woff"), url("../fonts/DINPro-CondensedBlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedMedium.eot");
  src: local("DIN Pro Condensed Medium"), local("DINPro-CondensedMedium"), url("../fonts/DINPro-CondensedMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedMedium.woff") format("woff"), url("../fonts/DINPro-CondensedMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-MediumItalic.eot");
  src: local("DIN Pro Medium Italic"), local("DINPro-MediumItalic"), url("../fonts/DINPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-MediumItalic.woff") format("woff"), url("../fonts/DINPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Bold.eot");
  src: local("DIN Pro Bold"), local("DINPro-Bold"), url("../fonts/DINPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Bold.woff") format("woff"), url("../fonts/DINPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Light.eot");
  src: local("DIN Pro Light"), local("DINPro-Light"), url("../fonts/DINPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Light.woff") format("woff"), url("../fonts/DINPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedMediumItalic.eot");
  src: local("DIN Pro Condensed Medium Italic"), local("DINPro-CondensedMediumItalic"), url("../fonts/DINPro-CondensedMediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedMediumItalic.woff") format("woff"), url("../fonts/DINPro-CondensedMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Italic.eot");
  src: local("DIN Pro Italic"), local("DINPro-Italic"), url("../fonts/DINPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Italic.woff") format("woff"), url("../fonts/DINPro-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro.eot");
  src: local("DIN Pro"), local("DINPro"), url("../fonts/DINPro.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro.woff") format("woff"), url("../fonts/DINPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedBlack.eot");
  src: local("DIN Pro Condensed Black"), local("DINPro-CondensedBlack"), url("../fonts/DINPro-CondensedBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedBlack.woff") format("woff"), url("../fonts/DINPro-CondensedBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedBold.eot");
  src: local("DIN Pro Condensed Bold"), local("DINPro-CondensedBold"), url("../fonts/DINPro-CondensedBold.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedBold.woff") format("woff"), url("../fonts/DINPro-CondensedBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-Black.eot");
  src: local("DIN Pro Black"), local("DINPro-Black"), url("../fonts/DINPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Black.woff") format("woff"), url("../fonts/DINPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-LightItalic.eot");
  src: local("DIN Pro Light Italic"), local("DINPro-LightItalic"), url("../fonts/DINPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-LightItalic.woff") format("woff"), url("../fonts/DINPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedBoldItalic.eot");
  src: local("DIN Pro Condensed Bold Italic"), local("DINPro-CondensedBoldItalic"), url("../fonts/DINPro-CondensedBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedBoldItalic.woff") format("woff"), url("../fonts/DINPro-CondensedBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedRegular.eot");
  src: local("DIN Pro Condensed Regular"), local("DINPro-CondensedRegular"), url("../fonts/DINPro-CondensedRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedRegular.woff") format("woff"), url("../fonts/DINPro-CondensedRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedItalic.eot");
  src: local("DIN Pro Condensed Italic"), local("DINPro-CondensedItalic"), url("../fonts/DINPro-CondensedItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedItalic.woff") format("woff"), url("../fonts/DINPro-CondensedItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedLightItalic.eot");
  src: local("DIN Pro Condensed Light Italic"), local("DINPro-CondensedLightItalic"), url("../fonts/DINPro-CondensedLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedLightItalic.woff") format("woff"), url("../fonts/DINPro-CondensedLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro Cond";
  src: url("../fonts/DINPro-CondensedLight.eot");
  src: local("DIN Pro Condensed Light"), local("DINPro-CondensedLight"), url("../fonts/DINPro-CondensedLight.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-CondensedLight.woff") format("woff"), url("../fonts/DINPro-CondensedLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("../fonts/DINPro-BoldItalic.eot");
  src: local("DIN Pro Bold Italic"), local("DINPro-BoldItalic"), url("../fonts/DINPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-BoldItalic.woff") format("woff"), url("../fonts/DINPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #F4F7F9;
  color: #131516;
  font-family: "DIN Pro";
  font-size: 16px;
  line-height: normal;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
  .container.container-small {
    max-width: 960px;
  }
}
.content-wrapper {
  flex-shrink: 0;
}

:focus {
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

.bg-blue-deep {
  background-color: #0E233C;
}

h1,
h2 {
  font-family: "DIN Pro Cond";
  font-weight: bold;
}

h1 {
  font-size: 48px;
}

.mbtm-1 {
  margin-bottom: 10px !important;
}

.mbtm-2 {
  margin-bottom: 20px !important;
}

.mbtm-3 {
  margin-bottom: 30px !important;
}

.mbtm-4 {
  margin-bottom: 40px !important;
}

.mbtm-5 {
  margin-bottom: 50px !important;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1002;
}
.navbar .navbar-brand {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  font-weight: bold;
}
.navbar .navbar-nav .nav-item #languageSelector,
.navbar .navbar-nav .nav-item #metricSelector,
.navbar .navbar-nav .nav-item #loginWindowBtn {
  position: relative;
  padding-left: 30px;
  margin-left: 20px;
}
.navbar .navbar-nav .nav-item #languageSelector::before,
.navbar .navbar-nav .nav-item #metricSelector::before,
.navbar .navbar-nav .nav-item #loginWindowBtn::before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  height: 21px;
  width: 21px;
}
.navbar .navbar-nav .nav-item #languageSelector::before {
  background-image: url(../images/earth.svg);
}
.navbar .navbar-nav .nav-item #metricSelector::before {
  background-image: url(../images/rules.svg);
}
.navbar .navbar-nav .nav-item #loginWindowBtn::before {
  background-image: url(../images/user-circle.svg);
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-weight: bold;
}
.navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 5px;
  min-width: 8rem;
}
.navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  font-weight: bold;
}
.navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  display: none;
}

/************************
 * Nav Menu on Hover
 ***********************/
/*only for screens greater than 992px  */
@media (min-width: 992px) {
  .navbar {
    min-height: 100px;
  }
  .navbar .navbar-nav > li.dropdown > ul {
    display: block;
    opacity: 0;
    visibility: hidden;
    transform: translateY(25px);
    /* subtle fade effect */
    -moz-transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  .navbar .navbar-nav > li.dropdown > ul > li .dropdown-item {
    border-radius: 6px;
    padding: 8px 15px;
  }
  .navbar .navbar-nav > li.dropdown > ul > li .dropdown-item:hover {
    background-color: #52B0F5;
    color: #ffffff;
  }
  .navbar .navbar-nav > li.dropdown:hover > ul {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .navbar .navbar-nav .nav-link {
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar .navbar-nav .nav-link:last-child {
    padding-right: 0;
  }
  .navbar .navbar-nav .nav-link.dropdown-toggle::after {
    display: none;
  }
}
.btn:not(.btn-large) {
  border-radius: 8px;
}

/************************
 * Buttons
 ***********************/
.btn {
  border: none;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  padding-top: 12px;
  padding-bottom: 12px;
}
.btn-lg {
  position: relative;
  background-color: #52B0F5;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  padding: 20px;
}
.btn-lg:hover {
  background-color: #52c1f5;
  color: #ffffff;
}
.btn-arrow {
  position: relative;
  background-color: #ffffff;
  font-size: 16px;
  text-transform: none;
  text-align: left;
  line-height: 20px;
  padding: 20px 54px 20px 20px;
}
.btn-arrow > i {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: auto;
  margin-right: 14px;
  transform: translateX(0) translateY(-50%);
  overflow: hidden;
  z-index: 0;
  height: 24px;
  width: 24px;
}
.btn-arrow > i::after, .btn-arrow > i::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  height: 24px;
  width: 24px;
}
.btn-arrow > i::before {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_547_226)'%3E%3Cpath d='M24 0H0V24H24V0Z' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M6 7H17V18' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 18L17 7' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_547_226'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  transform: translateX(0) translateY(0);
  transition-delay: 0.25s;
}
.btn-arrow > i::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_547_53)'%3E%3Cpath d='M24 0H0V24H24V0Z' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M21 12H3' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 6L21 12L15 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_547_53'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  transform: translateX(-24px) translateY(0);
  transition-delay: 0.25s;
}
.btn-arrow:hover {
  background-color: #52c1f5;
  color: #ffffff;
}
.btn-arrow:hover > i::before {
  transform: translateX(24px) translateY(-24px);
  transition-delay: 0s;
}
.btn-arrow:hover > i::after {
  transform: translateX(0) translateY(0);
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-primary {
  background-color: #52B0F5;
}
.btn-primary:hover {
  background-color: #52c1f5;
}
.btn-primary:hover:active, .btn-primary:hover:focus {
  background-color: #52B0F5;
}
.btn-primary:active, .btn-primary:focus {
  background-color: #52B0F5;
}
.btn-primary:active:hover, .btn-primary:focus:hover {
  background-color: #52c1f5;
}
.btn-secondary {
  background-color: #0E233C;
}
.btn-secondary:hover {
  background-color: #0e253c;
}

/************************
 * Input fields
 ***********************/
.form-label:has(+ .form-control:required),
.form-label:has(+ .form-select:required),
.form-label:has(+ .input-group .form-control:required),
.form-label:has(+ .input-group .form-select:required) {
  position: relative;
  padding-right: 10px;
}
.form-label:has(+ .form-control:required)::after,
.form-label:has(+ .form-select:required)::after,
.form-label:has(+ .input-group .form-control:required)::after,
.form-label:has(+ .input-group .form-select:required)::after {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  bottom: auto;
  content: "*";
  color: #E44343;
  font-size: 14px;
}

.form-control,
.form-select {
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.input-group-text {
  background-color: #EAEAEA;
  border: none;
  border-radius: 0 8px 8px 0;
  padding: 0.375rem 0.75rem;
  height: 100%;
}
.input-group-text:has(.pass-eye) {
  background-color: #ffffff;
}
.input-group-text .pass-eye {
  background-image: url(../images/eye.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}
.input-group-text .pass-eye.show {
  background-image: url(../images/eye-off.svg);
}

/************************
 * Switch (checkbox)
 ***********************/
.form-switch {
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-direction: row;
  gap: 1.4em;
}
.form-switch-center {
  justify-content: center;
}
.form-switch .form-check-input {
  position: relative;
  --bs-form-switch-bg: none;
  background-color: #3F4863;
  border: none;
  box-shadow: none;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  margin-top: -0.02em;
  margin-left: -2.9em;
  height: 0.85em;
  width: 2.4em;
}
.form-switch .form-check-input::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border-radius: 50%;
  background-color: #ffffff;
  transform: translateY(-0.18em);
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  height: 1.2em;
  width: 1.2em;
}
.form-switch .form-check-input:focus, .form-switch .form-check-input:active {
  --bs-form-switch-bg: none;
}
.form-switch .form-check-input:checked {
  --bs-form-switch-bg: none;
  background-color: rgba(82, 176, 245, 0.5);
}
.form-switch .form-check-input:checked::after {
  left: calc(100% - 1.2em);
  background-color: #52B0F5;
}

/************************
 * Modal windows
 ***********************/
.modal#registeringWindow .modal-dialog {
  max-width: 620px;
}
.modal .modal-dialog {
  width: fit-content;
  min-width: 330px;
  max-width: none;
}
.modal .modal-dialog-centered {
  margin-inline: auto;
}
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  border: none;
  padding: 30px;
}
.modal-dark {
  background-color: rgba(14, 35, 60, 0.45);
}
.modal-dark .modal-dialog .modal-content {
  background-color: #0E233C;
  color: #ffffff;
  border-radius: 14px;
  min-width: auto;
}
.modal-dark .modal-dialog .modal-content .modal-header .modal-title {
  border-bottom: 2px solid #52B0F5;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 3px;
  padding-bottom: 6px;
}
.modal-dark .modal-dialog .modal-content .modal-header .modal-title-btn {
  border-bottom: 2px solid transparent;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 3px;
  padding-bottom: 6px;
}
.modal-dark .modal-dialog .modal-content .modal-header .modal-title-btn:not(:last-child) {
  margin-right: 40px;
}
.modal-dark .modal-dialog .modal-content .modal-header .modal-title-btn.active, .modal-dark .modal-dialog .modal-content .modal-header .modal-title-btn:hover {
  border-bottom: 2px solid #52B0F5;
}
.modal-dark .modal-dialog .modal-content .modal-body {
  padding: 10px 30px 0;
}
.modal-dark .modal-dialog .modal-content .modal-footer {
  display: block;
}
.modal-dark .modal-dialog .modal-content .modal-footer .devider {
  border-bottom: 2px solid #3F4863;
  margin: 40px 0 20px;
}
.modal-dark#ForgotYourPassword .modal-dialog .modal-content .modal-body {
  padding-bottom: 0;
}
.modal-dark#ForgotYourPassword .modal-dialog .modal-content .modal-footer {
  padding-top: 0;
}
.modal-dark .d-block {
  display: block;
  margin: 0;
  min-width: 100%;
}
.modal .btn-close {
  --bs-btn-close-focus-shadow: none;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-opacity: 1;
  --bs-btn-close-opacity-hover: .5;
  transform: rotate(0deg);
  -moz-transition: opacity 0.25s ease;
  -webkit-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.modal .btn-close:hover {
  transform: rotate(90deg);
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.link {
  margin: 0;
}
.link-white {
  color: #ffffff;
  text-decoration: underline;
  text-decoration-color: rgba(255, 255, 255, 0.5);
}
.link-white:hover {
  text-decoration: underline;
  text-decoration-color: transparent;
}
.link-primary {
  color: #52B0F5;
}
.link:hover {
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

/************************
 * Application steps
 ***********************/
.app-steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  z-index: 2;
  width: auto;
}
.app-steps::after {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  background-color: #7B7F8C;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  z-index: 1;
}
.app-steps__item {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 20px;
  padding: 0 20px;
  background-color: #F4F7F9;
  color: #131516;
  text-decoration: none;
  z-index: 3;
  width: max-content;
}
.app-steps__item:first-child {
  padding-left: 0;
  padding-right: 20px;
}
.app-steps__item:last-child {
  padding-left: 20px;
  padding-right: 0;
}
.app-steps__item > i {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0E233C;
  color: #ffffff;
  border-radius: 50%;
  font-style: normal;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  height: 40px;
  width: 40px;
}
.app-steps__item.current, .app-steps__item.disabled {
  cursor: default;
  pointer-events: none;
}
.app-steps__item.current > i {
  background-color: #52B0F5;
}
.app-steps__item.disabled > i {
  background-color: #7B7F8C;
}
.app-steps__item.disabled > span {
  color: #7B7F8C;
}
.app-steps__item:hover:not(.disabled) > i {
  background-color: #52B0F5;
}

/************************
 * Homepage
 ***********************/
.page-home .content-wrapper {
  padding-bottom: 140px;
}
.page-home .content-wrapper .section .equipment-item {
  padding-top: 140px;
}

.equipment-item__link {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}
.equipment-item__link-image {
  position: relative;
  top: 0;
  right: 0;
  transform: translateX(-32.7%);
  width: auto;
  max-width: 180%;
}
.equipment-item__image-blured {
  position: relative;
  transform: translateX(-12%) translateY(22%);
  filter: blur(2px);
  max-height: 258px;
}
.equipment-item p {
  margin-bottom: 30px;
}
.equipment-item .btn {
  display: block;
  margin: 0 0 20px 0;
}
.equipment-item .btn-group {
  display: flex;
}
.equipment-item .btn-group .dropdown-toggle {
  position: relative;
  text-align: left;
  text-transform: none;
  outline: none;
  box-shadow: none;
  z-index: 1001;
}
.equipment-item .btn-group .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: 0;
  content: "";
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  height: 24px;
  width: 24px;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_547_53)'%3E%3Cpath d='M24 0H0V24H24V0Z' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M21 12H3' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 6L21 12L15 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_547_53'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  transform: translateX(-20px) translateY(-50%) rotate(90deg);
}
.equipment-item .btn-group .dropdown-toggle::after:hover {
  background-color: #52c1f5;
}
.equipment-item .btn-group .dropdown-toggle.show {
  background-color: #52B0F5;
}
.equipment-item .btn-group .dropdown-toggle.show:hover {
  background-color: #52c1f5;
}
.equipment-item .btn-group .dropdown-toggle.show::after {
  transform: translateX(-20px) translateY(-50%) rotate(270deg);
}
.equipment-item .btn-group .dropdown-menu {
  border: none;
  background-color: #F4F7F9;
  padding: 20px 0 0;
  opacity: 0;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  overflow-y: auto;
  min-width: 100%;
  max-height: 270px;
}
.equipment-item .btn-group .dropdown-menu.show {
  opacity: 1;
}

/************************
 * Footer
 ***********************/
.footer {
  background-color: #0E233C;
  color: #ffffff;
  font-size: 16px;
  padding: 40px 0;
  margin-top: auto;
}
.footer a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.footer a:not(:last-child) {
  margin-right: 40px;
}