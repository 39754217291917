@import "inc/colors";
@import "inc/fonts";

body {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $bg-main;
    color: $black-main;
    font-family: 'DIN Pro';
    font-size: 16px;
    line-height: normal;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1400px;

        &.container-small {
            max-width: 960px;
        }
    }
}

.content-wrapper {
    flex-shrink: 0;
}

:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;
}

.bg {
    &-blue-deep {
        background-color: $blue-deep;
    }
}

h1,
h2 {
    font-family: 'DIN Pro Cond';
    font-weight: bold;
}

h1 {
    font-size: 48px;
}

.mbtm-1 {
    margin-bottom: 10px !important;
}

.mbtm-2 {
    margin-bottom: 20px !important;
}

.mbtm-3 {
    margin-bottom: 30px !important;
}

.mbtm-4 {
    margin-bottom: 40px !important;
}

.mbtm-5 {
    margin-bottom: 50px !important;
}

.navbar {
    position: sticky;
    top: 0;
    z-index: 1002;

    .navbar-brand {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20px;
        font-size: 14px;
        font-weight: bold;
    }

    .navbar-nav {
        .nav-item {

            #languageSelector,
            #metricSelector,
            #loginWindowBtn {
                position: relative;
                padding-left: 30px;
                margin-left: 20px;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    content: '';
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    transform: translateY(-50%);
                    height: 21px;
                    width: 21px;
                }
            }

            #languageSelector {
                &::before {
                    background-image: url(../images/earth.svg);
                }
            }

            #metricSelector {
                &::before {
                    background-image: url(../images/rules.svg);
                }
            }

            #loginWindowBtn {
                &::before {
                    background-image: url(../images/user-circle.svg);
                }
            }

            .nav-link {
                color: $white;
                font-weight: bold;
            }

            .dropdown-menu {
                border: none;
                box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.05);
                padding: 5px;
                // min-width: auto;
                min-width: 8rem;

                .dropdown-item {
                    font-weight: bold;

                    &.active {
                        display: none;
                    }
                }
            }
        }
    }
}

/************************
 * Nav Menu on Hover
 ***********************/

/*only for screens greater than 992px  */
@media (min-width: 992px) {
    .navbar {
        min-height: 100px;

        .navbar-nav {
            >li {
                &.dropdown {
                    >ul {
                        display: block;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(25px);
                        /* subtle fade effect */
                        -moz-transition: all 0.25s ease;
                        -webkit-transition: all 0.25s ease;
                        -ms-transition: all 0.25s ease;
                        -o-transition: all 0.25s ease;
                        transition: all 0.25s ease;

                        >li {
                            .dropdown-item {
                                border-radius: 6px;
                                padding: 8px 15px;

                                &:hover {
                                    background-color: $blue-light;
                                    color: $white;
                                }
                            }
                        }
                    }

                    &:hover {
                        >ul {
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0);
                        }
                    }
                }
            }

            .nav-link {
                padding-left: 20px;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                }

                &.dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.btn {
    &:not(.btn-large) {
        border-radius: 8px;
    }
}

/************************
 * Buttons
 ***********************/

.btn {
    border: none;
    outline: none;
    box-shadow: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    padding-top: 12px;
    padding-bottom: 12px;

    &-lg {
        position: relative;
        background-color: $blue-light;
        color: $white;
        font-size: 16px;
        text-align: center;
        line-height: 20px;
        padding: 20px;

        &:hover {
            background-color: $blue-light-hovered;
            color: $white;
        }
    }

    &-arrow {
        position: relative;
        background-color: $white;
        font-size: 16px;
        text-transform: none;
        text-align: left;
        line-height: 20px;
        padding: 20px 54px 20px 20px;

        >i {
            position: absolute;
            top: 50%;
            left: auto;
            right: 0;
            bottom: auto;
            margin-right: 14px;
            transform: translateX(0) translateY(-50%);
            overflow: hidden;
            z-index: 0;
            height: 24px;
            width: 24px;

            &::after,
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                -moz-transition: all 0.25s ease;
                -webkit-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                transition: all 0.25s ease;
                height: 24px;
                width: 24px;
            }

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_547_226)'%3E%3Cpath d='M24 0H0V24H24V0Z' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M6 7H17V18' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 18L17 7' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_547_226'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                transform: translateX(0) translateY(0);
                transition-delay: .25s;
            }

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_547_53)'%3E%3Cpath d='M24 0H0V24H24V0Z' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M21 12H3' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 6L21 12L15 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_547_53'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                transform: translateX(-24px) translateY(0);
                transition-delay: .25s;
            }
        }

        &:hover {
            background-color: $blue-light-hovered;
            color: $white;

            >i {
                &::before {
                    transform: translateX(24px) translateY(-24px);
                    transition-delay: 0s;
                }

                &::after {
                    transform: translateX(0) translateY(0);
                }
            }
        }
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &-primary {
        background-color: $blue-light;

        &:hover {
            background-color: $blue-light-hovered;

            &:active,
            &:focus {
                background-color: $blue-light;
            }
        }

        &:active,
        &:focus {
            background-color: $blue-light;

            &:hover {
                background-color: $blue-light-hovered;
            }
        }
    }

    &-secondary {
        background-color: $blue-deep;

        &:hover {
            background-color: $blue-deep-hovered;
        }
    }
}

/************************
 * Input fields
 ***********************/

.form-label:has(+.form-control:required),
.form-label:has(+.form-select:required),
.form-label:has(+.input-group .form-control:required),
.form-label:has(+.input-group .form-select:required) {
    position: relative;
    padding-right: 10px;

    &::after {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        bottom: auto;
        content: '*';
        color: $red;
        font-size: 14px;
    }
}

.form-control,
.form-select {
    border: none !important;
    box-shadow: none !important;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}

.input-group {
    &-text {
        background-color: $gray-light;
        border: none;
        border-radius: 0 8px 8px 0;
        padding: .375rem .75rem;
        height: 100%;

        &:has(.pass-eye) {
            background-color: $white;
        }

        .pass-eye {
            background-image: url(../images/eye.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;

            &.show {
                background-image: url(../images/eye-off.svg);
            }
        }
    }
}

/************************
 * Switch (checkbox)
 ***********************/

.form-switch {
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: row;
    gap: 1.4em;

    &-center {
        justify-content: center;
    }

    .form-check-input {
        position: relative;
        --bs-form-switch-bg: none;
        background-color: $blue-dasty;
        border: none;
        box-shadow: none;
        -moz-transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        margin-top: -.02em;
        margin-left: -2.9em;
        height: .85em;
        width: 2.4em;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            border-radius: 50%;
            background-color: $white;
            transform: translateY(-.18em);
            -moz-transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
            height: 1.2em;
            width: 1.2em;
        }

        &:focus,
        &:active {
            --bs-form-switch-bg: none;
        }

        &:checked {
            --bs-form-switch-bg: none;
            background-color: rgba($blue-light, .5);

            &::after {
                left: calc(100% - 1.2em);
                background-color: $blue-light;
            }
        }
    }
}

/************************
 * Modal windows
 ***********************/

.modal {
    &#registeringWindow {
        .modal-dialog {
            max-width: 620px;
        }
    }

    .modal-dialog {
        width: fit-content;
        min-width: 330px;
        max-width: none;
    }

    .modal-dialog-centered {
        margin-inline: auto;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        border: none;
        padding: 30px;
    }

    &-dark {
        background-color: rgba($blue-deep, .45);

        .modal-dialog {

            .modal-content {
                background-color: $blue-deep;
                color: $white;
                border-radius: 14px;
                min-width: auto;

                .modal-header {
                    .modal-title {
                        border-bottom: 2px solid $blue-light;
                        font-size: 16px;
                        text-transform: uppercase;
                        // line-height: normal;
                        margin-top: 3px;
                        padding-bottom: 6px;

                        &-btn {
                            border-bottom: 2px solid transparent;
                            color: $white;
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                            text-decoration: none;
                            // line-height: normal;
                            margin-top: 3px;
                            padding-bottom: 6px;

                            &:not(:last-child) {
                                margin-right: 40px;
                            }

                            &.active,
                            &:hover {
                                border-bottom: 2px solid $blue-light;
                            }
                        }
                    }
                }

                .modal-body {
                    padding: 10px 30px 0;
                }

                .modal-footer {
                    display: block;

                    .devider {
                        border-bottom: 2px solid $blue-dasty;
                        margin: 40px 0 20px;
                    }
                }
            }
        }

        &#ForgotYourPassword {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        padding-bottom: 0;
                    }

                    .modal-footer {
                        padding-top: 0;
                    }
                }
            }
        }

        .d-block {
            display: block;
            margin: 0;
            min-width: 100%;
        }
    }

    .btn-close {
        --bs-btn-close-focus-shadow: none;
        --bs-btn-close-focus-opacity: 1;
        --bs-btn-close-opacity: 1;
        --bs-btn-close-opacity-hover: .5;

        transform: rotate(0deg);

        -moz-transition: opacity 0.25s ease;
        -webkit-transition: opacity 0.25s ease;
        -ms-transition: opacity 0.25s ease;
        -o-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;

        &:hover {
            transform: rotate(90deg);

            -moz-transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
        }
    }
}

.link {
    margin: 0;

    &-white {
        color: $white;
        text-decoration: underline;
        text-decoration-color: rgba($white, .5);

        &:hover {
            text-decoration: underline;
            text-decoration-color: transparent;
        }
    }

    &-primary {
        color: $blue-light;
    }

    &:hover {
        -moz-transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }
}

/************************
 * Application steps
 ***********************/

.app-steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
    width: auto;

    &::after {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        background-color: $smoke-gray;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        z-index: 1;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        gap: 20px;
        padding: 0 20px;
        background-color: $bg-main;
        color: $black-main;
        text-decoration: none;
        z-index: 3;
        width: max-content;

        &:first-child {
            padding-left: 0;
            padding-right: 20px;
        }

        &:last-child {
            padding-left: 20px;
            padding-right: 0;
        }

        >i {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue-deep;
            color: $white;
            border-radius: 50%;
            font-style: normal;
            -moz-transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
            height: 40px;
            width: 40px;
        }

        &.current,
        &.disabled {
            cursor: default;
            pointer-events: none;
        }

        &.current {
            >i {
                background-color: $blue-light;
            }
        }

        &.disabled {
            >i {
                background-color: $smoke-gray;
            }

            >span {
                color: $smoke-gray;
            }
        }

        &:hover {
            &:not(.disabled) {
                >i {
                    background-color: $blue-light;
                }
            }
        }
    }
}

/************************
 * Homepage
 ***********************/

.page-home {
    .content-wrapper {
        padding-bottom: 140px;

        .section {
            .equipment-item {
                padding-top: 140px;
            }
        }
    }
}

.equipment-item {
    &__link {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;

        &-image {
            position: relative;
            top: 0;
            right: 0;
            transform: translateX(-32.7%);
            width: auto;
            max-width: 180%;
        }
    }

    &__image-blured {
        position: relative;
        transform: translateX(-12%) translateY(22%);
        filter: blur(2px);
        max-height: 258px;
    }

    p {
        margin-bottom: 30px;
    }

    .btn {
        display: block;
        margin: 0 0 20px 0;
    }

    .btn-group {
        display: flex;

        .dropdown-toggle {
            position: relative;
            text-align: left;
            text-transform: none;
            outline: none;
            box-shadow: none;
            z-index: 1001;

            &::after {
                position: absolute;
                top: 50%;
                left: auto;
                right: 0;
                bottom: 0;
                content: '';
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                -moz-transition: all 0.25s ease;
                -webkit-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                transition: all 0.25s ease;
                height: 24px;
                width: 24px;
                border: none;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_547_53)'%3E%3Cpath d='M24 0H0V24H24V0Z' fill='white' fill-opacity='0.01'/%3E%3Cpath d='M21 12H3' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 6L21 12L15 18' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_547_53'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                transform: translateX(-20px) translateY(-50%) rotate(90deg);

                &:hover {
                    background-color: $blue-light-hovered;
                }
            }

            &.show {
                background-color: $blue-light;

                &:hover {
                    background-color: $blue-light-hovered;
                }

                &::after {
                    transform: translateX(-20px) translateY(-50%) rotate(270deg);
                }
            }
        }

        .dropdown-menu {
            border: none;
            background-color: $bg-main;
            padding: 20px 0 0;
            opacity: 0;
            -moz-transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
            overflow-y: auto;
            min-width: 100%;
            max-height: 270px;

            &.show {
                opacity: 1;
            }
        }
    }
}

/************************
 * Footer
 ***********************/

.footer {
    background-color: $blue-deep;
    color: $white;
    font-size: 16px;
    padding: 40px 0;
    margin-top: auto;

    a {
        color: $white;
        font-weight: bold;
        text-decoration: none;

        &:not(:last-child) {
            margin-right: 40px;
        }
    }
}