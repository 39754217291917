$bg-main: #F4F7F9;
$gray-light: #EAEAEA;
$white: #ffffff;
$blue-light: #52B0F5;
$blue-light-hovered: #52c1f5;
$blue-deep: #0E233C;
$blue-deep-hovered: #0e253c;
$blue-dasty: #3F4863;
$black-main: #131516;
$smoke-gray: #7B7F8C;
$red: #E44343;